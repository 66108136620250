import './output.css';
import ScenarioCheckboxInput from './Components/Scenarios/ScenarioCheckboxInput';
import Scenarios from './Components/Scenarios/Scenarios';
import Recommendations from './Components/Scenarios/Recommendations';
import { useLocalStorage } from './useLocalStorage';


import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Root from './Components/Root';
import ErrorPage from './Components/Main/ErrorPage';
import Summary from './Components/Summary/Summary';
import Help from './Components/Help/Help';

function App() {
  // Initialize scenario Local Storage varible
  useLocalStorage('scenario',  JSON.stringify(new Array(36).fill(0)));
  const [scenario, setScenerio] = useLocalStorage('scenario',  JSON.stringify(new Array(36).fill(0)));



  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: <Root scenario={scenario}/>,
        errorElement: <ErrorPage />,
        children: [
          {
            path: "scenarios",
            element: <Scenarios />,
          },
          {
            path: "recommendation/:scenerioIndex",
            element: <Recommendations  />,
          },
          {
            path: "scenario/:scenerioIndex",
            element: <ScenarioCheckboxInput />,
          },
          {
            path: "summary",
            element: <Summary scenario={scenario}/>
          },
          {
            path: "help",
            element: <Help />
          }
        ],
      },
    ]
  )

  return (
    <RouterProvider router={router} />
  );
}

export default App;
