import { Outlet } from "react-router-dom"
import ThemeSwitcher from "./Main/ThemeSwitcher";
// import NavBottom from "./Main/NavBottom";
import Intro from "./Main/Intro";
import UserForm from "./Main/UserForm";
import { useState, useEffect } from "react";
import IntroModal from "./Main/IntroModal";
import i18next from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import { useLocalStorage } from './../useLocalStorage';


export default function Root( ) {

  let [isOpen, setIsOpen] = useState(false);
  let [isOpenIntro, setIsOpenIntro] = useState(true);
  let [langFromURL, setLangFromURL] = useState("et");
  const [scenario, setScenario] = useLocalStorage('scenario', JSON.stringify(new Array(36).fill(0)));
  const [demo, setDemo] = useLocalStorage( "demo", JSON.stringify(false) );
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();

  function openModal() {
    setIsOpen(true)
  }

  const handleClickScroll = () => {
    openModal();
    setTimeout(() => {
      const element = document.getElementById('headingScenarios');
      if (element) {
        // Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' });
      }
      // Hide Hero area on first click
      const hero = document.getElementById('hero');
      hero.classList.add("hidden");

    }, 2500);
  };

  function setLangFromURLtoEN() {
    setLangFromURL("en");
  }

  function setCustomLocalStorage() {
    /* Fill with data everything except scenarios 2 and 6 */
    let scenario_var = [4,4,4,4,0,0,0,0,4,4,4,4,4,4,4,4,4,4,4,4,4,4,4,0,0,0,0,0,4,4,4,4,4,4,4,4];
    setScenario(JSON.stringify(scenario_var));
    setDemo(JSON.stringify(true));
  }

  function changeMeta() {
    document.title = i18n.t("meta_title");
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', i18n.t("meta_description"));
    }
  }

  useEffect(( ) => {

    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);

    if( params.get("lang") === "en" ){
      setLangFromURLtoEN();
    }

    if ( params.get("demo") === "true" ) {
      setCustomLocalStorage();
    }
    
    i18n.changeLanguage(langFromURL);
    changeMeta();
   
  }, [ langFromURL, demo ]);
   

  return (
    <div className="App">
      <ThemeSwitcher />
      <IntroModal isOpen={isOpenIntro} setIsOpen={setIsOpenIntro}/>
      <Intro handleClickScroll={handleClickScroll} scenario={scenario} />

      <UserForm isOpen={isOpen} setIsOpen={setIsOpen}/>

      <Outlet />
      {/*<NavBottom />*/}
    </div>
  )
}
